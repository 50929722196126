import React from "react";
import * as styles from "./infoCard.module.css";

export default function InfoCard({ imgSrc, imgAlt, title, children }) {
  return (
    <div className={styles.whyOnlineBenefit}>
      <div className={styles.whyOnlineImage}>
        <img src={imgSrc} alt={title} />
      </div>
      <div className={styles.whyOnlineDescription}>
        <h4>{title}</h4>
        {children}
      </div>
    </div>
  );
}
