import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import InfoCard from "../../components/InfoCard";
import * as baseStyles from "../index.module.css";
import * as styles from "./pos.module.css";

// images
import borrowMoney from "../../images/1-borrow-money.jpg";
import fundsPayback from "../../images/2-funds-payback.jpg";
import businessFreedom from "../../images/3-business-freedom.jpg"

export default function BusinessLoans() {
  return (
    <Layout>
      <Helmet>
        <title>Business Loans | MyEZSolutions</title>
      </Helmet>

      <div className={baseStyles.pageContent}>
        <div className={baseStyles.pageTitle}>
          <h1>Merchant Cash Advance</h1>
          <p>
            We help finance your business goals with funding in as little as 3
            business days! Contact us for a fast and free funding analysis.
          </p>
        </div>

        <div className={styles.whyOnlineParent}>
          <div className={styles.whyOnlineHead}>
            In today’s marketplace, fast access to cash is critical for
            merchants. Positive cash flow from a merchant cash advance puts the
            ball in your court. Secure your business funding and open a world of
            new growth opportunities for your business!
            <br />
            <br />
            <ul style={{ textAlign: "left" }}>
              <li>Get up to $500,000 per business location</li>
              <li>
                Same day pre-approval – once approved, receive your funds in
                less than a week
              </li>
              <li>No collateral required, no interest fees and no red tape</li>
              <li>
                Flexible repayment plan – repay using a fixed amount or with a
                small percentage of future credit card sales
              </li>
              <li>On average, 90% or merchants who contact us are approved</li>
            </ul>
          </div>
          <h4 className={styles.whyOnlineTagline}>READY, SET, GROW!</h4>
          <div className={styles.whyOnlineHead}>
            <p>
              Grow your business with a smart merchant cash advance program.
              Marketing and advertising, inventory purchases, payroll expenses,
              expansion or renovations, or emergency funds. We offer the perfect
              alternative to bank loans. Contact us today for a free
              consultation and a no-cost funding analysis. There is no
              obligation whatsoever associated with the application process.
            </p>
          </div>
          <div className={styles.onlineBenefits}>
            <InfoCard
              imgSrc={borrowMoney}
              title="How much money can you borrow?"
            >
              <p>
                We offer up to $500,000 in funding per business location. The
                precise amount depends on your credit and debit card sales
                volume or gross monthly sales. Once approved, you receive the
                funds in a matter of days. Contact us for a fast and free
                funding analysis.
              </p>
            </InfoCard>
            <InfoCard
              imgSrc={fundsPayback}
              title="How do you pay back the funds?"
            >
              <p>
                It’s simple: a small percentage of your credit and debit card
                sales are used to repay funds. That means we don’t get paid
                until you do. The better your business does, the faster the
                repayment. If you prefer, you may also repay with a
                predetermined fixed daily or weekly amount.
              </p>
            </InfoCard>
            <InfoCard
              imgSrc={businessFreedom}
              title="Are there restrictions to how you can use the money?"
            >
              <p>
                No, we don’t limit what you use the money for. Whether it’s to
                pay off a business loan, advertise, pay your staff, buy
                inventory, pay taxes or even go on a well-deserved vacation, you
                are free to use your cash advance as you see fit.
              </p>
            </InfoCard>
          </div>
        </div>
        <div style={{ marginBottom: "2em" }}>
          <a className={baseStyles.heroCta} href={"/get-quote"}>
            Get Started
          </a>
        </div>
      </div>
    </Layout>
  );
}
